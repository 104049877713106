const env: string | undefined = process.env.VUE_APP_ENV

export enum BugSeqEnv {
  Dev,
  Stag,
  Prod,
}

export function getEnv (): BugSeqEnv {
  if (env === undefined) {
    throw new Error('APP_ENV env var must be defined')
  }

  // these match our backend env values and our aws resources
  switch (env) {
    case 'production':
    case 'prod':
      return BugSeqEnv.Prod
    case 'staging':
    case 'stag':
      return BugSeqEnv.Stag
    case 'development':
    case 'dev':
      return BugSeqEnv.Dev
  }

  throw new Error(`unrecognized env: ${env}`)
}

export function mustGetEnv (envVar: string): string {
  if (process.env[envVar] === undefined || process.env[envVar] === null || process.env[envVar] === '') {
    throw new Error(`${envVar} must be defined`)
  }
  return process.env[envVar]
}

export function getApiUrl (): string {
  return mustGetEnv('VUE_APP_API_URL')
}

export function getWwwUrl (): string {
  return mustGetEnv('VUE_APP_WWW_URL')
}

export function getAppUrl (): string {
  return mustGetEnv('VUE_APP_APP_URL')
}

export function getCurrentAppUrl (): string {
  return mustGetEnv('VUE_APP_CURRENT_URL')
}
