import { ActionContext } from 'vuex'
import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '../../state'
import { commitAddNotification, commitRemoveNotification } from './mutations'
import { Notification, NotificationState } from './state'

type NotificationContext = ActionContext<NotificationState, RootState>

export const actions = {
  async addNotification (context: NotificationContext, payload: Notification) {
    commitAddNotification(context, payload)
  },
  async removeNotification (
    context: NotificationContext,
    payload: { notification: Notification, timeout?: number }
  ) {
    if (payload.timeout !== undefined && payload.timeout > 0) {
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          commitRemoveNotification(context, payload.notification)
          resolve(true)
        }, payload.timeout)
      })
    }
    commitRemoveNotification(context, payload.notification)
  }
}

const { dispatch } = getStoreAccessors<NotificationState | any, RootState>('notifications')
export const dispatchAddNotification = dispatch(actions.addNotification)
export const dispatchRemoveNotification = dispatch(actions.removeNotification)
