import { Module } from 'vuex'
import { NotificationState } from './state'
import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'

export const notifications: Module<NotificationState, any> = {
  namespaced: true,
  state: {
    notifications: []
  },
  actions,
  mutations,
  getters
}
