<template>
  <v-container class="pa-0">
    <p class="home-section-title my-4">How does it work?</p>
    <Workflow />
    <p class="mt-4">
      BugSeq will automatically determine what’s in your sample and run
      pathogen-specific analysis.
    </p>
    <p class="mt-4">
      All pathogens undergo:
    </p>
    <ul>
      <li>Quality control</li>
      <li>Taxonomic classification</li>
      <li>Antimicrobial resistance prediction</li>
      <li>Detailed epidemiological subtyping</li>
    </ul>
    <p class="mt-4">
      Additionally, we provide more extensive analysis for some of the most
      commonly-sequenced pathogens. See our
      <a href="/pathogen-pipelines" class="white--text"
        >pathogen-specific pipelines</a
      >
      for details.
    </p>
    <v-btn to="/pathogen-pipelines" class="text-none ml-0"
      >Pathogen-specific pipelines</v-btn
    >
  </v-container>
</template>

<script>
import Workflow from "@bugseq-site/www/src/components/home/Workflow";

export default {
  components: {
    Workflow,
  },
};
</script>

<style>
.no_underline {
  text-decoration: none;
}
</style>
