import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '../../state'
import { Notification, NotificationState } from './state'

export const mutations = {
  addNotification (state: NotificationState, payload: Notification) {
    // assume previous notifs can be nuked
    state.notifications = [payload]
  },
  removeNotification (state: NotificationState, payload: Notification) {
    state.notifications = state.notifications.filter(
      (notification) => notification !== payload
    )
  }
}

const { commit } = getStoreAccessors<NotificationState, RootState>('notifications')

export const commitAddNotification = commit(mutations.addNotification)
export const commitRemoveNotification = commit(mutations.removeNotification)
