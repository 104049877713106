import { getStoreAccessors } from 'typesafe-vuex'
import { NotificationState } from './state'
import { RootState } from '../../state'

export const getters = {
  firstNotification: (state: NotificationState) => state.notifications?.[0]
}

const { read } = getStoreAccessors<NotificationState, RootState>('notifications')

export const readFirstNotification = read(getters.firstNotification)
